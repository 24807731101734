import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey, postmarkServerToken } from '../../constants/apiConstants';
import './AdminConfig.css';

const supabase = createClient(supabaseUrl, supabaseKey);

function AdminConfig() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [searchRadius, setSearchRadius] = useState(10);
  const [results, setResults] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false);

  const handleTriggerAutoReport = async () => {
    try {
      setLoading(true);
      setMessage('Triggering auto report...');
      
      const response = await fetch('https://auto.ionws.com/webhook/ajaxautoreport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResults(data);
      setMessage('Successfully triggered auto report');

    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const formatResultsToHtml = (results) => {
    const tableStyle = 'border-collapse: collapse; width: 100%; margin-top: 20px;';
    const thStyle = 'border: 1px solid #ddd; padding: 12px; background-color: #f5f5f5; text-align: left;';
    const tdStyle = 'border: 1px solid #ddd; padding: 12px;';

    const tableRows = results.map(result => `
      <tr>
        <td style="${tdStyle}">${result.client_name}</td>
        <td style="${tdStyle}">${result.address}, ${result.city}, ${result.state}</td>
        <td style="${tdStyle}">
          <ul style="list-style: none; padding-left: 0; margin: 0;">
            <li>Storm Events: ${result.report_summary.total_storm_events}</li>
            <li>Hail Sightings: ${result.report_summary.total_hail_sightings}</li>
            <li>Closest Event: ${result.report_summary.closest_event_miles.toFixed(2)} miles</li>
          </ul>
        </td>
      </tr>
    `).join('');

    return `
      <h2>Hail Report Results</h2>
      <table style="${tableStyle}">
        <thead>
          <tr>
            <th style="${thStyle}">Client Name</th>
            <th style="${thStyle}">Location</th>
            <th style="${thStyle}">Report Summary</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;
  };

  const handleTestEmail = async () => {
    try {
      setSendingEmail(true);
      setMessage('Sending report email...');

      const response = await fetch('https://auto.ionws.com/webhook/ajax-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          htmlContent: formatResultsToHtml(results)
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMessage('Report email sent successfully!');
    } catch (error) {
      setMessage(`Error sending email: ${error.message}`);
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <div className="admin-config">
      <h2>Auto Report Processing</h2>
      
      <div className="config-section">
        <label>
          Search Radius (miles):
          <input
            type="number"
            value={searchRadius}
            onChange={(e) => setSearchRadius(Number(e.target.value))}
            min="1"
            max="100"
          />
        </label>
      </div>

      <div className="admin-actions">
        <button 
          onClick={handleTriggerAutoReport} 
          className="function-button" 
          disabled={loading}
        >
          Trigger Auto Report
        </button>
        <button 
          onClick={handleTestEmail}
          className="function-button test-button"
          disabled={sendingEmail || !results}
        >
          {sendingEmail ? 'Sending...' : 'Email Report'}
        </button>
      </div>

      {message && <div className="message">{message}</div>}

      {results && (
        <div className="results-section">
          <h3>Processing Results</h3>
          {results.map((result, index) => (
            <div key={index} className="result-card">
              <h4>{result.client_name}</h4>
              <p>
                {result.address}, {result.city}, {result.state}
              </p>
              <div className="report-summary">
                <strong>Summary:</strong>
                <ul>
                  <li>Total Storm Events: {result.report_summary.total_storm_events}</li>
                  <li>Total Hail Sightings: {result.report_summary.total_hail_sightings}</li>
                  <li>Closest Event: {result.report_summary.closest_event_miles.toFixed(2)} miles</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminConfig;