import React, { useState } from 'react';
import Papa from 'papaparse';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import useGeocoding from '../../hooks/useGeocoding';

const supabase = createClient(supabaseUrl, supabaseKey);

function CsvUpload({ clientId, onUploadSuccess }) {  // renamed selectedClientId to clientId
  const [csvFile, setCsvFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { getCoordinates } = useGeocoding();

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!csvFile || !clientId) return;  // updated to use clientId
    setIsUploading(true);

    Papa.parse(csvFile, {
      header: true,
      complete: async (results) => {
        try {
          const assets = results.data.filter(asset => asset.address && asset.city && asset.state);
          const assetsWithCoordinates = await Promise.all(
            assets.map(async (asset) => {
              const fullAddress = `${asset.address}, ${asset.city}, ${asset.state}`;
              const { lat, lng } = await getCoordinates(fullAddress);
              return { 
                ...asset, 
                client_id: clientId,  // updated to use clientId
                lat, 
                lng 
              };
            })
          );

          const { data, error } = await supabase
            .from('assets')
            .insert(assetsWithCoordinates)
            .select();

          if (error) throw error;

          onUploadSuccess(data || []);
          setCsvFile(null);
        } catch (error) {
          console.error('Error uploading assets:', error);
          alert('Error uploading assets: ' + error.message);
        } finally {
          setIsUploading(false);
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        alert('Error parsing CSV file');
        setIsUploading(false);
      },
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-6 hover:border-primary-light transition-colors">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          disabled={isUploading}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        />
        <div className="text-center">
          <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
          </svg>
          <p className="mt-1 text-sm text-gray-600">
            {csvFile ? csvFile.name : 'Click to select or drag and drop a CSV file'}
          </p>
        </div>
      </div>

      <button
        onClick={handleUpload}
        disabled={!csvFile || isUploading}
        className={`w-full py-2 px-4 rounded-md text-white font-medium transition-colors
          ${!csvFile || isUploading
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-primary-light hover:bg-primary cursor-pointer'
          }`}
      >
        {isUploading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
            </svg>
            Uploading...
          </span>
        ) : (
          'Upload CSV'
        )}
      </button>
    </div>
  );
}

export default CsvUpload;