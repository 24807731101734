import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import CsvUpload from './CsvUpload';
import useStormEvents from '../../hooks/useStormEvents';
import './ClientManager.css'; // Import the CSS file

const supabase = createClient(supabaseUrl, supabaseKey);

// Add this new component at the top of the file
const DeleteConfirmationDialog = ({ isOpen, business_name, onConfirm, onCancel }) => {
  const [confirmText, setConfirmText] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-11/12 max-w-lg">
        <h3 className="text-xl font-bold text-red-600 mb-4">Delete Confirmation</h3>
        <p>To confirm deletion, please type: <strong>{business_name}</strong></p>
        <input
          type="text"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          placeholder="Type business name to confirm"
          className="w-full p-2 border rounded mt-4 mb-4"
        />
        <div className="flex justify-end gap-2">
          <button 
            onClick={() => onConfirm(confirmText)}
            className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700"
          >
            Delete
          </button>
          <button 
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Add this new component after DeleteConfirmationDialog
const EditClientDialog = ({ isOpen, client, onSave, onCancel }) => {
  const [editedClient, setEditedClient] = useState(null);

  useEffect(() => {
    if (client) {
      setEditedClient(client);
    }
  }, [client]);

  if (!isOpen || !editedClient) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-11/12 max-w-lg">
        <h3 className="text-xl font-bold mb-4">Edit Client</h3>
        <div className="space-y-4">
          <input
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Business Name"
            value={editedClient.business_name || ''}
            onChange={(e) => setEditedClient({ ...editedClient, business_name: e.target.value })}
          />
          <input
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Website"
            value={editedClient.website || ''}
            onChange={(e) => setEditedClient({ ...editedClient, website: e.target.value })}
          />
          <input
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Mailing Address"
            value={editedClient.mailing_address || ''}
            onChange={(e) => setEditedClient({ ...editedClient, mailing_address: e.target.value })}
          />
          <input
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Contact Name"
            value={editedClient.contact_name || ''}
            onChange={(e) => setEditedClient({ ...editedClient, contact_name: e.target.value })}
          />
          <input
            className="w-full p-2 border rounded"
            type="email"
            placeholder="Contact Email"
            value={editedClient.contact_email || ''}
            onChange={(e) => setEditedClient({ ...editedClient, contact_email: e.target.value })}
          />
          <input
            className="w-full p-2 border rounded"
            type="text"
            placeholder="Contact Phone"
            value={editedClient.contact_phone || ''}
            onChange={(e) => setEditedClient({ ...editedClient, contact_phone: e.target.value })}
          />
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <button 
            onClick={() => onSave(editedClient)}
            className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary"
          >
            Save
          </button>
          <button 
            onClick={onCancel}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Update the CsvHelpTooltip component with better styling
const CsvHelpTooltip = () => {
  return (
    <div className="group relative inline-block ml-2">
      <button className="w-6 h-6 rounded-full bg-gray-200 text-gray-600 hover:bg-gray-300 flex items-center justify-center text-sm font-semibold">?</button>
      <div className="hidden group-hover:block absolute z-50 w-80 p-4 mt-2 -ml-72 text-sm bg-white rounded-lg shadow-xl border border-gray-200">
        <div className="flex items-center mb-3">
          <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span className="font-bold text-gray-800">CSV File Format</span>
        </div>
        <div className="bg-gray-50 p-3 rounded-md mb-3">
          <p className="font-semibold text-gray-700 mb-2">Required Headers:</p>
          <ul className="space-y-1 text-gray-600">
            <li className="flex items-center">
              <span className="w-20 font-medium">address</span>
              <span className="text-gray-500">- Street address</span>
            </li>
            <li className="flex items-center">
              <span className="w-20 font-medium">city</span>
              <span className="text-gray-500">- City name</span>
            </li>
            <li className="flex items-center">
              <span className="w-20 font-medium">state</span>
              <span className="text-gray-500">- State abbreviation</span>
            </li>
          </ul>
        </div>
        <div className="text-xs text-gray-500 bg-blue-50 p-2 rounded">
          <span className="font-medium">Example:</span> address,city,state
        </div>
      </div>
    </div>
  );
};

// Add new CsvUploadDialog component
const CsvUploadDialog = ({ isOpen, onClose, clientId, onUploadSuccess }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-bold text-gray-800">Upload Assets CSV</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
          <div className="flex items-center mb-2">
            <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="font-semibold text-blue-800">File Requirements</span>
          </div>
          <p className="text-sm text-blue-600">
            Please ensure your CSV file includes the required headers: address, city, state
          </p>
        </div>

        <div className="mb-6">
          <CsvUpload
            clientId={clientId}
            onUploadSuccess={(assets) => {
              onUploadSuccess(assets);
              onClose();
            }}
          />
        </div>

        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default function ClientManager() {
  const [clients, setClients] = useState([]);
  const [assets, setAssets] = useState([]);
  const [newClient, setNewClient] = useState({
    business_name: '',
    website: '',
    mailing_address: '',
    contact_name: '',
    contact_email: '',
    contact_phone: ''
  });
  const [newAsset, setNewAsset] = useState({ address: '', city: '', state: '' }); // removed report_url
  const [selectedClient, setSelectedClient] = useState(null);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [showAddAssetForm, setShowAddAssetForm] = useState(false);
  const [showUploadCsvForm, setShowUploadCsvForm] = useState(false);
  const [assetPage, setAssetPage] = useState(1);
  const assetPageSize = 10;
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, clientId: null, businessName: '' });
  const [editDialog, setEditDialog] = useState({ isOpen: false, client: null });
  const [searchQuery, setSearchQuery] = useState('');
  const [assetSearchQuery, setAssetSearchQuery] = useState('');

  const { getCoordinates } = useStormEvents(supabase);

  useEffect(() => {
    fetchClients();
    fetchAssets();
  }, []);

  const fetchClients = async () => {
    const { data, error } = await supabase.from('clients').select('*');
    if (error) {
      console.error('Error fetching clients:', error);
    } else {
      setClients(data || []);
    }
  };

  const fetchAssets = async () => {
    const { data, error } = await supabase.from('assets').select('*');
    if (error) {
      console.error('Error fetching assets:', error);
    } else {
      setAssets(data || []);
    }
  };

  const addClient = async () => {
    const { error } = await supabase.from('clients').insert([newClient]);
    if (error) {
      console.error('Error adding client:', error);
    } else {
      // Fetch the updated clients list
      await fetchClients();
      setNewClient({
        business_name: '',
        website: '',
        mailing_address: '',
        contact_name: '',
        contact_email: '',
        contact_phone: ''
      });
      setShowAddClientForm(false);
    }
  };

  const addAsset = async () => {
    if (!selectedClient) return;
    try {
      const { lat, lng } = await getCoordinates(newAsset.address);
      const assetWithCoordinates = { ...newAsset, client_id: selectedClient, lat, lng };
      const { error } = await supabase.from('assets').insert([assetWithCoordinates]);
      if (error) {
        console.error('Error adding asset:', error);
      } else {
        // Fetch the updated assets list
        const { data: updatedAssets, error: fetchError } = await supabase.from('assets').select('*');
        if (!fetchError) {
          setAssets(updatedAssets || []);
          setNewAsset({ address: '', city: '', state: '' });
          setShowAddAssetForm(false);
        }
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const deleteClient = async (clientId, event) => {
    event.stopPropagation();
    const client = clients.find(c => c.id === clientId);
    setDeleteConfirmation({ 
      isOpen: true, 
      clientId, 
      businessName: client.business_name 
    });
  };

  const handleDeleteConfirm = async (confirmText) => {
    const { clientId, businessName } = deleteConfirmation;
    
    if (confirmText !== businessName) {
      alert("Business name doesn't match. Deletion cancelled.");
      setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' });
      return;
    }

    try {
      const { error: assetsError } = await supabase
        .from('assets')
        .delete()
        .eq('client_id', clientId);

      if (assetsError) throw assetsError;

      const { error: clientError } = await supabase
        .from('clients')
        .delete()
        .eq('id', clientId);

      if (clientError) throw clientError;

      setAssets(assets.filter(asset => asset.client_id !== clientId));
      setClients(clients.filter(client => client.id !== clientId));
      
      if (selectedClient === clientId) {
        setSelectedClient(null);
      }
    } catch (error) {
      console.error('Error deleting client:', error);
    }
    
    setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' });
  };

  const deleteAsset = async (assetId) => {
    if (window.confirm('Are you sure you want to delete this asset?')) {
      const { error } = await supabase.from('assets').delete().eq('id', assetId);
      if (error) {
        console.error('Error deleting asset:', error);
      } else {
        setAssets(assets.filter(asset => asset.id !== assetId));
      }
    }
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
    setShowAddAssetForm(false);
    setShowUploadCsvForm(false);
  };

  const handleCsvUpload = (uploadedAssets) => {
    setAssets([...assets, ...uploadedAssets]);
  };

  const handleEdit = async (clientId, event) => {
    event.stopPropagation();
    const client = clients.find(c => c.id === clientId);
    setEditDialog({ isOpen: true, client });
  };

  const handleEditSave = async (editedClient) => {
    try {
      const { error } = await supabase
        .from('clients')
        .update(editedClient)
        .eq('id', editedClient.id);

      if (error) throw error;

      setClients(clients.map(c => 
        c.id === editedClient.id ? editedClient : c
      ));
      setEditDialog({ isOpen: false, client: null });
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  const filteredClients = clients.filter(client => {
    const query = searchQuery.toLowerCase();
    return (
      client.business_name?.toLowerCase().includes(query) ||
      client.contact_name?.toLowerCase().includes(query) ||
      client.contact_email?.toLowerCase().includes(query)
    );
  });

  const filteredAssets = selectedClient
    ? assets.filter((asset) => {
        const query = assetSearchQuery.toLowerCase();
        const matchesClient = asset.client_id === selectedClient;
        const matchesSearch = !query || 
          asset.address?.toLowerCase().includes(query) ||
          asset.city?.toLowerCase().includes(query) ||
          asset.state?.toLowerCase().includes(query);
        return matchesClient && matchesSearch;
      })
    : [];

  // Calculate total pages
  const totalPages = Math.ceil(filteredAssets.length / assetPageSize);
  
  // Ensure current page is valid
  useEffect(() => {
    if (assetPage > totalPages && totalPages > 0) {
      setAssetPage(totalPages);
    }
  }, [assetPage, totalPages]);

  const paginatedAssets = filteredAssets.slice((assetPage - 1) * assetPageSize, assetPage * assetPageSize);

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8">Client Manager</h2>

      <section className="bg-gray-50 p-6 rounded-lg mb-8">
        <h3 className="text-center mb-4">
          <button onClick={() => setShowAddClientForm(!showAddClientForm)}
                  className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary">
            {showAddClientForm ? 'Cancel' : 'Add New Client'}
          </button>
        </h3>
        {showAddClientForm && (
          <div className="space-y-4">
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Business Name"
              value={newClient.business_name}
              onChange={(e) => setNewClient({ ...newClient, business_name: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Website"
              value={newClient.website}
              onChange={(e) => setNewClient({ ...newClient, website: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Mailing Address"
              value={newClient.mailing_address}
              onChange={(e) => setNewClient({ ...newClient, mailing_address: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Contact Name"
              value={newClient.contact_name}
              onChange={(e) => setNewClient({ ...newClient, contact_name: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="email"
              placeholder="Contact Email"
              value={newClient.contact_email}
              onChange={(e) => setNewClient({ ...newClient, contact_email: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Contact Phone"
              value={newClient.contact_phone}
              onChange={(e) => setNewClient({ ...newClient, contact_phone: e.target.value })}
            />
            <button onClick={addClient}
                    className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary">
              Add Client
            </button>
          </div>
        )}
      </section>

      <section className="bg-gray-50 p-6 rounded-lg mb-8">
        <h3 className="text-xl font-bold mb-4">Clients</h3>
        
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search clients..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>

        <table className="w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-3 text-center">Business Name</th>
              <th className="p-3 text-center">Contact Name</th>
              <th className="p-3 text-center">Contact Email</th>
              <th className="p-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map((client) => (
              <tr
                key={client.id}
                onClick={() => handleClientSelect(client.id)}
                className={`border-b hover:bg-gray-50 cursor-pointer ${
                  selectedClient === client.id ? 'bg-secondary bg-opacity-10' : ''
                }`}
              >
                <td className="p-3 text-center">{client.business_name}</td>
                <td className="p-3 text-center">{client.contact_name}</td>
                <td className="p-3 text-center">{client.contact_email}</td>
                <td className="p-3 text-center">
                  <span 
                    className="text-blue-600 cursor-pointer hover:underline mr-4"
                    onClick={(e) => handleEdit(client.id, e)}
                  >
                    Edit
                  </span>
                  <span 
                    className="text-red-600 cursor-pointer hover:underline"
                    onClick={(e) => deleteClient(client.id, e)}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section className="bg-gray-50 p-6 rounded-lg mb-8">
        <h3 className="text-xl font-bold mb-4">Assets</h3>
        {selectedClient && (
          <div className="flex items-center mb-4">
            <button 
              onClick={() => setShowAddAssetForm(!showAddAssetForm)}
              className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
              {showAddAssetForm ? 'Cancel' : 'Add New Asset'}
            </button>
            <button 
              onClick={() => setShowUploadCsvForm(!showUploadCsvForm)}
              className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary ml-2 flex items-center"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
              </svg>
              {showUploadCsvForm ? 'Cancel' : 'Upload CSV'}
            </button>
            <CsvHelpTooltip />
          </div>
        )}

        <CsvUploadDialog
          isOpen={showUploadCsvForm}
          onClose={() => setShowUploadCsvForm(false)}
          clientId={selectedClient}
          onUploadSuccess={handleCsvUpload}
        />

        {/* Add New Asset Form */}
        {showAddAssetForm && (
          <div className="space-y-4 mb-6 bg-white p-4 rounded-lg border">
            <h4 className="font-semibold text-lg mb-4">Add New Asset</h4>
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="Street Address"
              value={newAsset.address}
              onChange={(e) => setNewAsset({ ...newAsset, address: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="City"
              value={newAsset.city}
              onChange={(e) => setNewAsset({ ...newAsset, city: e.target.value })}
            />
            <input
              className="w-full p-2 border rounded"
              type="text"
              placeholder="State"
              value={newAsset.state}
              onChange={(e) => setNewAsset({ ...newAsset, state: e.target.value })}
            />
            <button
              onClick={addAsset}
              className="bg-primary-light text-black px-4 py-2 rounded hover:bg-primary"
            >
              Add Asset
            </button>
          </div>
        )}

        {/* Add this search input for assets */}
        {selectedClient && (
          <div className="mt-4 mb-4">
            <input
              type="text"
              placeholder="Search assets by address, city, or state..."
              value={assetSearchQuery}
              onChange={(e) => setAssetSearchQuery(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
        )}

        <table className="w-full mt-4">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-3 text-center">Address</th>
              <th className="p-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedAssets.map((asset) => (
              <tr key={asset.id} className="border-b hover:bg-gray-50">
                <td className="p-3 text-center">{`${asset.address}, ${asset.city}, ${asset.state}`}</td>
                <td className="p-3 text-center">
                  <span className="text-red-600 cursor-pointer hover:underline" onClick={() => deleteAsset(asset.id)}>Delete</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedClient && filteredAssets.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={() => setAssetPage(assetPage - 1)}
              disabled={assetPage === 1}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="text-gray-700">
              Page {assetPage} of {totalPages}
            </span>
            <button
              onClick={() => setAssetPage(assetPage + 1)}
              disabled={assetPage >= totalPages}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </section>
      
      <DeleteConfirmationDialog
        isOpen={deleteConfirmation.isOpen}
        business_name={deleteConfirmation.businessName}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' })}
      />
      <EditClientDialog
        isOpen={editDialog.isOpen}
        client={editDialog.client}
        onSave={handleEditSave}
        onCancel={() => setEditDialog({ isOpen: false, client: null })}
      />
    </div>
  );
}