import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

export { markerIcon, markerIconRetina, markerShadow };

export const searchIcon = L.ExtraMarkers.icon({
  icon: 'fa-search',
  markerColor: 'orange',  // Changed from red to orange to match theme
  shape: 'circle',
  prefix: 'fa',
  iconColor: 'black',     // Changed from white to black for better contrast
});