import React, { useState } from 'react';
import SearchForm from './SearchForm';
import EventsMap from './EventsMap';
import EventsTable from './EventsTable';
import useStormEvents from '../../hooks/useStormEvents';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';

const supabase = createClient(supabaseUrl, supabaseKey);

function StormEvents() {
  const {
    address,
    setAddress,
    coordinates,
    events,
    totalCount,
    loading,
    error,
    dataSource,
    setDataSource,
    handleSearch,
    radius,
    setRadius,
    yearsBack,
    setYearsBack,
  } = useStormEvents(supabase);

  const [selectedLocation, setSelectedLocation] = useState(null);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
        Hail Spottings Search
      </h2>
      <div className="mb-8">
        <SearchForm
          address={address}
          setAddress={setAddress}
          radius={radius}
          setRadius={setRadius}
          yearsBack={yearsBack}
          setYearsBack={setYearsBack}
          dataSource={dataSource}
          setDataSource={setDataSource}
          onSearch={handleSearch}
          loading={loading}
        />
      </div>
      <div className="mb-8">
        <EventsMap
          coordinates={coordinates}
          events={events}
          dataSource={dataSource}
          selectedLocation={selectedLocation}
        />
      </div>
      {error && <p className="text-red-600 mb-4">{error}</p>}
      {coordinates && (
        <p className="text-gray-600 mb-4">
          Search Location: Latitude: {coordinates.lat.toFixed(4)}, Longitude: {coordinates.lng.toFixed(4)}
        </p>
      )}
      <EventsTable
        events={events}
        totalCount={totalCount}
        coordinates={coordinates}
        dataSource={dataSource}
        onRowClick={setSelectedLocation}
        selectedLocation={selectedLocation}
      />
    </div>
  );
}

export default StormEvents;