import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import StormEvents from './components/StormEvents/StormEvents';
import ClientManager from './components/ClientManager/ClientManager';
import ReportGenerator from './components/Reports/ReportGenerator';
import PasswordForm from './components/Authentication/PasswordForm';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminConfig from './components/Admin/AdminConfig';
import './App.css';
import logo from './assets/images/logo.webp'; // Add this import

function AppContent() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <PasswordForm onAuthenticate={() => setIsAuthenticated(true)} />;
  }

  return (
    <div className="App">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="app-logo" />
        <div className="logo-text">Guardian</div>
      </div>
      <nav className="bg-primary-light shadow-md">
        <ul className="flex justify-center space-x-8 py-4 px-6">
          <li>
            <Link to="/" className="text-black hover:text-primary transition-colors duration-200 font-medium">
              Hail Search
            </Link>
          </li>
          <li>
            <Link to="/clients" className="text-black hover:text-primary transition-colors duration-200 font-medium">
              Client Manager
            </Link>
          </li>
          <li>
            <Link to="/reports" className="text-black hover:text-primary transition-colors duration-200 font-medium">
              Report Generator
            </Link>
          </li>
          {/* Auto Report link temporarily disabled
          <li>
            <Link to="/auto-report" className="text-black hover:text-primary transition-colors duration-200 font-medium">
              Auto Report
            </Link>
          </li>
          */}
        </ul>
      </nav>
      <main>
        <Routes>
          <Route path="/" element={<StormEvents />} />
          <Route path="/clients" element={<ClientManager />} />
          <Route path="/reports" element={<ReportGenerator />} />
          {/* <Route path="/auto-report" element={<AdminConfig />} /> */}
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;