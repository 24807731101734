import React, { useState } from 'react';

function PasswordForm({ onAuthenticate }) {
  const [password, setPassword] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'HailYeah!!') {
      onAuthenticate();
      localStorage.setItem('lastAuthTime', Date.now().toString());
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <form onSubmit={handlePasswordSubmit} className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="text-center mb-8">
          <img 
            src="/static/media/logo.08b4f8212fac02ba8a4f.webp" 
            alt="Guardian Logo"
            className="mx-auto mb-6 w-32 h-auto"
          />
          <h1 className="text-4xl font-bold text-primary mb-2">GUARDIAN</h1>
          <p className="text-gray-600 text-sm">by Ajax Consulting</p>
        </div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
          placeholder="Enter password"
        />
        <button 
          type="submit" 
          className="w-full bg-primary hover:bg-primary-light text-white font-bold py-2 px-4 rounded-md transition duration-300"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default PasswordForm;