import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import ExtraMarkers from 'leaflet-extra-markers';
import { calculateOpacity } from '../../utils/dateUtils';
import { markerIcon, markerIconRetina, markerShadow, searchIcon } from '../../constants/mapConstants';

// Fix Leaflet's default icon URLs
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

function EventsMap({ coordinates, events, dataSource, radius, selectedLocation }) {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const searchMarkerRef = useRef(null);

  useEffect(() => {
    if (!mapInstanceRef.current && mapRef.current && coordinates) {
      mapInstanceRef.current = L.map(mapRef.current).setView([coordinates.lat, coordinates.lng], getZoomLevel(radius));
  
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(mapInstanceRef.current);
    }

    if (mapInstanceRef.current && coordinates) {
      mapInstanceRef.current.setView([coordinates.lat, coordinates.lng], getZoomLevel(radius));
  
      if (searchMarkerRef.current) {
        searchMarkerRef.current.setLatLng([coordinates.lat, coordinates.lng]);
      } else {
        searchMarkerRef.current = L.marker([coordinates.lat, coordinates.lng], { icon: searchIcon })
          .addTo(mapInstanceRef.current)
          .bindPopup('Search Address');
      }
    }
  }, [coordinates, radius]);

  useEffect(() => {
    if (mapInstanceRef.current && selectedLocation) {
      mapInstanceRef.current.setView(
        [selectedLocation.lat, selectedLocation.lon],
        getZoomLevel(radius)
      );
    }
  }, [selectedLocation, radius]);

  useEffect(() => {
    if (mapInstanceRef.current && coordinates && events.length > 0) {
      // Remove all event markers, but preserve the search marker
      mapInstanceRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer !== searchMarkerRef.current) {
          mapInstanceRef.current.removeLayer(layer);
        }
      });

      // Create marker cluster group
      const markerCluster = L.markerClusterGroup({
        maxClusterRadius: 40,
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true
      });

      // Create a map to track markers at each coordinate
      const coordMap = new Map();

      // First pass: group markers by coordinates with validation
      events.forEach(event => {
        let lat, lon;
        
        // Extract coordinates based on data source with validation
        if (dataSource === 'NOAA') {
          lat = parseFloat(event.BEGIN_LAT);
          lon = parseFloat(event.BEGIN_LON);
        } else if (dataSource === 'Radar') {
          lat = parseFloat(event.LAT);
          lon = parseFloat(event.LON);
        } else {
          lat = parseFloat(event.Lat);
          lon = parseFloat(event.Lon);
        }

        // Skip invalid coordinates
        if (isNaN(lat) || isNaN(lon)) {
          console.warn('Invalid coordinates for event:', event);
          return;
        }

        const key = `${lat},${lon}`;
        if (!coordMap.has(key)) {
          coordMap.set(key, []);
        }
        coordMap.get(key).push(event);
      });

      // Second pass: create markers with offsets if needed
      coordMap.forEach((eventsAtLocation, coordKey) => {
        const [baseLat, baseLon] = coordKey.split(',').map(Number);
        
        // Validate base coordinates
        if (isNaN(baseLat) || isNaN(baseLon)) {
          console.warn('Invalid base coordinates:', coordKey);
          return;
        }

        eventsAtLocation.forEach((event, index) => {
          // Calculate offset for stacked markers
          const offsetFactor = 0.0001; // About 11 meters at the equator
          const adjustedLat = baseLat + (index * offsetFactor);
          const adjustedLon = baseLon + (index * offsetFactor);

          // Additional validation for adjusted coordinates
          if (isNaN(adjustedLat) || isNaN(adjustedLon)) {
            console.warn('Invalid adjusted coordinates for event:', event);
            return;
          }

          const magnitude = dataSource === 'NOAA' ? (event.MAGNITUDE || 'N/A') 
                          : (dataSource === 'Radar' ? event.MAXSIZE : event.SizeInInches);
          
          const opacity = calculateOpacity(
            dataSource === 'Radar' ? event.ZTIME : event.BEGIN_DATE_TIME
          );

          const customIcon = L.divIcon({
            className: 'custom-div-icon',
            html: `
              <div style="
                background-color: rgba(248, 181, 3, ${opacity}); 
                width: 30px; 
                height: 30px; 
                border-radius: 50%; 
                display: flex; 
                justify-content: center; 
                align-items: center; 
                color: black; 
                font-weight: bold;
                font-size: 10px;
                ${eventsAtLocation.length > 1 ? 'border: 2px solid white;' : ''}
              ">
                ${magnitude}
                ${eventsAtLocation.length > 1 ? `<span style="font-size: 8px; position: absolute; bottom: -8px; right: -8px; background: #f8b503; border-radius: 50%; padding: 2px 4px;">${eventsAtLocation.length}</span>` : ''}
              </div>
            `,
            iconSize: [30, 30],
            iconAnchor: [15, 15]
          });

          // Create and add the marker
          const marker = L.marker([adjustedLat, adjustedLon], { icon: customIcon });
          
          // Define magnitudeType before popup content
          const magnitudeType = dataSource === 'NOAA' ? (event.MAGNITUDE_TYPE || '') 
                              : (dataSource === 'Radar' ? 'inches' : 'inches');

          let popupContent;
          if (dataSource === 'NOAA') {
            popupContent = `
              <b>${event.EVENT_TYPE}</b><br>
              Date: ${new Date(event.BEGIN_DATE_TIME).toLocaleString()}<br>
              Size: ${magnitude} ${magnitudeType}<br>
              Comment: ${dataSource === 'NOAA' ? event.EVENT_NARRATIVE : event.Comments || 'N/A'}<br>
              ${dataSource === 'NOAA' ? `Source: ${event.SOURCE || 'N/A'}<br>` : ''}
            `;
          } else if (dataSource === 'Radar') {
            popupContent = `
              <b>Radar Data</b><br>
              Date: ${new Date(event.ZTIME).toLocaleString()}<br>
              Size: ${event.MAXSIZE} inches<br>
              WSR ID: ${event.WSR_ID}<br>
              Cell ID: ${event.CELL_ID}<br>
              Range: ${event.RANGE} km<br>
              Azimuth: ${event.AZIMUTH} degrees<br>
              Severity Probability: ${event.SEVPROB}%<br>
              Probability: ${event.PROB}%<br>
            `;
          } else {
            const date = new Date(event.BEGIN_DATE_TIME);
            popupContent = `
              <b>Hail Sighting</b><br>
              Size: ${event.SizeInInches} inches<br>
              Date: ${date.toLocaleDateString()}<br>
              Time: ${date.toLocaleTimeString()}<br>
              Location: ${event.Location || 'N/A'}<br>
              Comment: ${event.Comments || 'N/A'}
            `;
          }

          marker.bindPopup(popupContent);
          markerCluster.addLayer(marker);
        });
      });

      // Add the cluster group to the map
      mapInstanceRef.current.addLayer(markerCluster);
    }
  }, [events, dataSource, coordinates, radius]);

  // Helper function to determine zoom level based on radius
  const getZoomLevel = (radius) => {
    const radiusInMiles = radius / 1609.34; // Convert meters to miles
    if (radiusInMiles <= 1) return 14;
    if (radiusInMiles <= 2) return 13;
    if (radiusInMiles <= 5) return 12;
    if (radiusInMiles <= 10) return 11;
    return 10; // Default zoom level for larger radii
  };

  return <div class="map-container leaflet-container" ref={mapRef}></div>;
}

export default EventsMap;